.AddSerial{
    display:grid;
    border: 1px solid #ced4da;
    padding: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.PFButton {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    text-align: center;
}

.RaisedButton {
    margin-left: 10px!important;
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    height: 45px!important;
    text-align: center;
}