.Home .lander {
  padding: 0px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

#bottomPage {
  background: -moz-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
  background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
  background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.7)), to(rgba(29, 210, 177, 0.7)));
  background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
  background: linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
  margin-top: 50px;
}

@font-face {
  font-family: saxony-regular;
  src: url(Saxony-Regular.otf);
}

@font-face {
  font-family: saxony-demibold;
  src: url(Saxony-DemiBold.otf);
}

@font-face {
  font-family: saxony-light;
  src: url(Saxony-Light.otf);
}

.logo-scp {
  font-family: saxony-light;
}

.Sure {
  color: #444343!important;
  font-size: 5rem;
  font-weight: 100;
}

.Can {
  color: #006b8f!important;
  font-size: 5rem;
  font-weight: 100;
}

.Pay {
  color: #006b8f!important;
  font-family: saxony-demibold;
  font-weight: 1000;
  font-size: 5rem;
}