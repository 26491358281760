.md-tabs{
    color:#fff!important;
    margin-bottom:1.2rem!important;
}

.info2{
    margin-left:30px!important;
}

.smallInfoBar{
    margin-bottom:1rem;
    margin-left:0px;
}

