.code{
    background-color: #f5f5f5;
    padding:3px;
    margin-top:5px;
    margin-bottom: 5px;
}

.code code{
    color:black!important;
}

.quote{
    margin-left:10px;
    padding-left:10px;
    padding-bottom:5px;
    margin-bottom: 5px;
    margin-top:5px;
    border-left: double grey;
    background-color: #f5f5f5;
}